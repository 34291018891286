<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REMITTANCES TRANSACTION HISTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3>
          <v-select
            v-model="month_of"
            class="mx-2"
            dense
            outlined
            label="Month of"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            @change="selected_month"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="bank_id"
            class="mx-2"
            dense
            outlined
            label="Bank"
            :items="bank_items"
            item-value="id"
            item-text="bank_code"
            @change="selected_bank"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            v-model="account_no"
            class="mx-2"
            dense
            outlined
            label="Account #"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            v-model="last_month_balance"
            class="mx-2"
            dense
            outlined
            label="Last Month Balance"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            v-model="total_deposit"
            class="mx-2"
            dense
            outlined
            label="Total Deposit"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            v-model="total_withdrew"
            class="mx-2"
            dense
            outlined
            label="Total Withdrew"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            v-model="remaining_balance"
            class="mx-2"
            dense
            outlined
            label="Remaining Balance"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3 v-if="data_items.length>0">
          Print |
          <v-icon class="mr-2" color="success"
                  @click="print_data()">
            {{ icons.mdiPrinter }}
          </v-icon>
          <v-progress-circular color="info" indeterminate
                               v-if="is_deleting"></v-progress-circular>
        </v-flex>
      </v-layout>
      <v-data-table dense
                    class="mt-3"
                    :headers="headers"
                    :items="data_items"
                    disable-pagination
                    hide-default-footer
                    height="550"
                    :search="amount_to_search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="amount_to_search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.deposit===''?'':formatPrice(item.deposit) }}
            </td>
            <td>
              {{ item.withdrew===''?'':formatPrice(item.withdrew) }}
            </td>
            <td>
              {{ item.cheque_no===0?'':item.cheque_no}}
            </td>
            <td>
              <v-chip>{{ item.date }}</v-chip>
            </td>
            <td>
              {{ formatPrice(item.balance)}}
            </td>
            <td>
              {{ item.particulars }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="can_edit" persistent max-width="30%">
      <EditData :key="key" :data="transaction_data" :category_id="this.category_id"
                v-on:response="on_change"></EditData>
      <v-btn color="error" @click="can_edit = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiAccountSearchOutline,
    mdiPencil,
    mdiDelete,
    mdiPrinter
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import EditData from '../input_interface/cash_flow/EditData'

  const initialState = () => {
    return {
      is_deleting: false,
      saving_data: false,
      can_edit: false,
      is_final: false,
      key: 0,
      transaction_data: {},

      category_id: '',
      amount_to_search: '',
      month_of: '',
      sorted_by: '',
      category_items: [],
      month_of_items: [],
      data_items: [],
      bank_id: 0,
      bank_balance: 0,
      bank_items: [],
      account_no: '',
      last_month_balance: '0.00',
      total_deposit: '0.00',
      total_withdrew: '0.00',
      remaining_balance: '0.00',
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Dep. Amount', value: 'deposit', sortable: false},
        {text: 'With. Amount', value: 'withdrew', sortable: false},
        {text: 'Cheque #', value: 'cheque_no', sortable: false},
        {text: 'Date', value: 'date', sortable: false},
        {text: 'Balance', value: 'balance', sortable: false},
        {text: 'Particulars', value: 'particulars', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
      EditData,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiAccountSearchOutline,
          mdiPencil,
          mdiDelete,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'employee_id',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee_compensation_history', ['initialize_salary_increase_report', 'list_of_salary_increase_report']),
      ...mapActions('bank_transaction', ['initialization_remittances_transaction_history', 'get_transaction_of_bank']),
      ...mapActions('palawan_transactions', [
        'get_transaction_of_palawan_history',
      ]),
      initialize_data() {
        this.initialize_salary_increase_report()
          .then(response => {
            this.month_of_items = response.data[0].month_of
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('month_of_id', this.month_of);
        data.append('is_current', -1);
        this.initialization_remittances_transaction_history(data)
          .then(response => {
            this.category_items = response.data
            this.category_id = ''
            this.bank_id = ''
            this.bank_items = []
            this.data_items = []
            this.account_no = ''
            this.last_month_balance = ''
            this.total_deposit = ''
            this.total_withdrew = ''
            this.remaining_balance = ''
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category(value) {
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.is_final = !(this.category_items[index].month_of_id === this.month_of)
          this.bank_items = this.category_items[index].bank_depository
          this.bank_id = ''
          this.data_items = []
          this.account_no = ''
          this.last_month_balance = ''
          this.total_deposit = ''
          this.total_withdrew = ''
          this.remaining_balance = ''
        }
      },
      selected_bank(value) {
        this.data_items = []
        this.account_no = ''
        this.last_month_balance = ''
        this.total_deposit = ''
        this.total_withdrew = ''
        this.remaining_balance = ''
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.account_name = this.bank_items[index].bank_name
          this.account_no = this.bank_items[index].account_no
          const data = new FormData()
          data.append('month_of_id', this.month_of);
          data.append('bank_id', value);
          data.append('is_cash_flow', 0);

          data.append('category_id', this.category_id)
          data.append('type_of_remittances', this.bank_items[this.bank_items.map(function (x) {
            return x.id;
          }).indexOf(value)].bank_code.includes('PALAWAN') ? 'palawan' : 'Cebuana')
          this.get_transaction_of_palawan_history(data)
            .then(response => {
              this.data_items = response.data[0].bank_transaction_history
              this.last_month_balance = response.data[0].last_month_balance
              this.total_deposit = response.data[0].total_deposit
              this.total_withdrew = response.data[0].total_withdrew
              this.remaining_balance = response.data[0].remaining_balance
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      async edit_details(value) {
        this.can_edit = true
        this.transaction_data = value
        this.key++
      },
      async on_change(value) {
        var color = 'error'
        if (value.status === 200) {
          color = 'success'
        }
        this.change_snackbar({
          show: true,
          color: color,
          text: value.data,
        })
        this.can_edit = false
        await this.selected_bank(this.bank_id)
      },
      async delete_bank_transaction(value) {
        this.saving_data = true
        const data = new FormData()
        data.append('id', value.id)
        await this.delete_cash_flow(data)
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'DELETED SUCCESSFULLY',
            })
          })
          .catch(error => {
            console.log(error)
          })
        await this.selected_bank(this.bank_id)
        this.saving_data = false
      },
      async print_data() {
        this.is_deleting = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printingss_ = this.printing

        widths = [45, 45, 40, 40, 60, 250]
        var tot = this.total_amount
        payments_array.push([
          {text: 'Dep. Amount', alignment: 'left', style: 'main_info'},
          {text: 'With. Amount', alignment: 'left', style: 'main_info'},
          {text: 'Cheque #', alignment: 'left', style: 'main_info'},
          {text: 'Date', alignment: 'left', style: 'main_info'},
          {text: 'Balance', alignment: 'left', style: 'main_info'},
          {text: 'Particulars', alignment: 'left', style: 'main_info'},
        ])

        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.deposit === '' ? '' : (item.deposit / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.withdrew === '' ? '' : (item.withdrew / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.cheque_no === 0 ? '' : item.cheque_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.date,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.balance / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.particulars,
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Month Of: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Category: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Bank: ',
                  {
                    text: this.bank_items[
                      this.bank_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.bank_id)
                      ].bank_code,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Account #: ',
                  {
                    text: this.account_no,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Last Month Balance: ',
                  {
                    text: this.last_month_balance,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Total Deposit: ',
                  {
                    text: this.total_deposit,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Total Withdrew: ',
                  {
                    text: this.total_withdrew,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Remaining Balance: ',
                  {
                    text: this.remaining_balance,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LEGAL',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'LABOTTEGA',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'BANK TRANSACTION HISTORY',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.is_deleting = false
      }
    },
  }
</script>
